import React from "react";
import "./Footer.scss";
import {
	POLICY,
	PRIVACY,
	TERMS_OF_USE,
} from "../../routes/constants";

let currentYear = new Date().getFullYear();

function Footer() {

	return (
		<div className="footer">
			<a href={TERMS_OF_USE} alt="" className="footer-link">
				Terms of Service
			</a>
			<div className="footer-legal">-</div>
			<a href={PRIVACY} alt="" className="footer-link">
				Privacy Policy
			</a>
			<div className="footer-legal">-</div>
			<a href={POLICY} alt="" className="footer-link">
				Multiple Funding Application Policy
			</a>
			<div className="footer-legal">-</div>
			<div className="footer-legal">All Rights Reserved &copy; {currentYear}</div>
		</div>
	)
}

export default Footer;